import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,

  image2: {
    width: "50%",
    height: "400px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "50%",
      height: "350px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      width: "auto",
      height: "210px",
      display: "flex",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div style={{ padding: "60px 0px 0px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              Fixture Upgrades
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              We take every plumbing issue seriously and are 100% dedicated to
              helping our clients, no matter the scope of the project. From
              installing new plumbing fixtures, valves to entirely new
              construction, GoPro Plumbing has the necessary expertise and
              experience in all aspects of residential plumbing.
            </p>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <div align="center">
            <iframe
              className={classes.image2}
              src="https://www.youtube.com/embed/1Fx9M9YIc0M"
              frameBorder="0"
              allow="encrypted-media; gyroscope;"
              allowFullScreen
              align="center"
              title="Leak detection system"
            />
          </div>
        </Grid>
      </div>
    </div>
  );
}
